import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollTop";
import PrivateJobPosting from "../pages/PrivateJobPosting/index.jsx";

// Lazy loading and memoizing route components
const NotFound = React.memo(lazy(() => import("../pages/404")));
const Account = React.memo(lazy(() => import("../pages/Account")));
const VisitingAdminDashboard = React.memo(
  lazy(() => import("../pages/VisitingAdminDashboard"))
);
const Job = React.memo(lazy(() => import("../pages/GovernmentJob")));
const GovernmentJobHome = React.memo(
  lazy(() => import("../pages/GovernmentJobHome"))
);
const PrivacyPolicy = React.memo(
  lazy(() => import("../pages/privacyPolicy/index.jsx"))
);

const UnauthorizedRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Suspense
        fallback={
          <div className="loading_container">
            <img
              src="../publicAssets/companyLogo.jpg"
              alt="loading_icon"
              srcSet=""
            />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<GovernmentJobHome />} />
          <Route path="/policy/:id" element={<PrivacyPolicy />} />
          <Route path="/register" element={<VisitingAdminDashboard />} />
          <Route path="/job/:tab" element={<Job />} />
          <Route path="/private-job/:tab" element={<PrivateJobPosting />} />
          <Route path="/account/:name" element={<Account />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default UnauthorizedRoutes;
