import React, { useMemo } from "react";
import styles from "./index.module.css";
import { formatDate } from "../latestGovernmentJob";
import { useLocation } from "react-router";
import { tags } from "../exploreTopGovernmentJob/data.js";
import { Link } from "react-router-dom";

const JobContainer = ({ item, keyValue }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchTexts = queryParams.get("searchText");
  const tag = queryParams.get("tag");
  const pageNumber = queryParams.get("page") || 1;

  const handleNavigation = useMemo(() => {
    return `/job/${convertToSlug(item.jobTitle)}--${item._id}?searchText=${
      searchTexts || ""
    }&tag=${tag || ""}`;
  }, [item.jobTitle, searchTexts, tag]); // Dependencies

  return (
    <div key={keyValue} className={styles.job_list_item}>
      <div className={styles.job_heading}>
        <h2>{getTitle(item.jobTitle)}</h2>
      </div>
      <div className={styles.job_body}>
        <div className={styles.job_body_top}>
          <div>
            <h3 className={styles.job_category}>
              {item.organization === "Nursing Org"
                ? item.jobTitle?.split(" ")[0]
                : item.organization}{" "}
              <span>{}</span>
            </h3>
            <div className={styles.tags}>
              {filterTags(item.tags || [], tags).map((tag) => (
                <p key={tag.value}>{tag.value}</p>
              ))}
            </div>
          </div>
          <div>
            <img
              className={styles.job_category_icon}
              src={getIcon(item.category)}
              alt={item.category}
            />
          </div>
        </div>
        <div className={styles.job_description}>
          <p>{truncateText(item.jobDescription || "")}</p>
        </div>
        <div className={styles.job_body_bottom}>
          <div>
            {item.numberOfVacancies && (
              <div>
                <h3>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/12984/12984048.png"
                    alt="Vacancy icon"
                  />
                  Vacancy :
                </h3>
                <p>{item.numberOfVacancies}</p>
              </div>
            )}
            {item.location && (
              <div>
                <h3>
                  <img
                    src="https://cdn-icons-png.freepik.com/512/12540/12540526.png"
                    alt="location icon"
                  />
                </h3>
                <p>{item.location}</p>
              </div>
            )}
            {item.salary && (
              <div>
                <h3>
                  <img
                    src="https://cdn-icons-png.freepik.com/512/3681/3681402.png"
                    alt="salary icon"
                  />
                </h3>
                <p>{item.salary} ₹</p>
              </div>
            )}
            {item.createdAt && (
              <div>
                <h3>
                  <img
                    src="https://cdn-icons-png.freepik.com/256/3143/3143636.png"
                    alt="posted icon"
                  />{" "}
                  Posted:
                </h3>
                <p>
                  {formatDate(item.createdAt || "2024-07-13T13:47:14.405Z")}
                </p>
              </div>
            )}
          </div>
          <div>
            <Link to={handleNavigation}>
              <button>Full Details</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobContainer;

function truncateText(paragraph) {
  const words = paragraph.split("");

  if (words.length > 520) {
    const truncated = words.slice(0, 520).join("");
    return truncated;
  }

  return paragraph;
}

function getIcon(text) {
  switch (text) {
    case "Nursing":
      return "../publicAssets/governmentJobHome/nurse_icon.png";
    case "Doctor":
      return "../publicAssets/governmentJobHome/doctor_icon.png";
    case "Paramedic":
      return "../publicAssets/governmentJobHome/paramedic_icon.png";
    default:
      return "../publicAssets/governmentJobHome/doctor_icon.png";
  }
}

export function getTagName(text) {
  switch (text) {
    case "Nursing":
      return "Nurse";
    case "Doctor":
      return "Doctor";
    case "Paramedic":
      return "Paramedical";
    default:
      return "Medical";
  }
}

export const getTitle = (title) => {
  if (title) {
    const data =
      title.split("Apply for")[1] ||
      title.split("Apply Online for")[1] ||
      title.split("Recruitment")[0] ||
      title.split("Vacancy")[0];
    if (data) {
      return data.trim().replace(/^\d+\s*/, "");
    } else {
      return title;
    }
  }
};
function convertToSlug(text) {
  return text
    .toLowerCase() // Convert the string to lowercase
    .replace(/[\s\W-]+/g, "-") // Replace spaces and non-word characters with hyphens
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
}
export function filterTags(tags, data) {
  const lowerTags = tags.map((tag) => tag.toLowerCase());

  return data.filter((item) => lowerTags.includes(item.value.toLowerCase()));
}
