import React, { useEffect, useRef, useState } from 'react'
import styles from "./index.module.css"
import menuList from "./data/menulist.json"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import RegistrationForm from '../../components/RegistrationPopUp'
import MobileViewNav from './mobileNav'
import AutoSuggestJobs from '../../components/AutoSuggestJobs'
import stateCity from "../../components/AutoSuggestJobs/stateCity.json"

const Navbar = () => {
 
  const navigate = useNavigate()
   
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search);


  // Example: Get specific query parameters
  const searchVal = queryParams.get('searchText');

  const [showDropdown, setShowDropdown] = useState(null)
  
  const [showMobileMenu,setShowMobileMenu] = useState(false)

   const { user ,isLoggedIn} = useSelector((state) => state.auth);

   const [searchText,setSearchText] = useState("")
   const [finalSuggestion,setFinalSuggestion] = useState("")
  const [showRegistrations,setShowRegistrations] = useState(false)
// //console.log(user,isLoggedIn)
const [isProfileOpen, setIsProfileOpen] = useState(false);
const profileBoxRef = useRef(null);

const handleProfileClick = () => {
  setIsProfileOpen(!isProfileOpen);
};

// const handleClickOutside = (event) => {
//   if (profileBoxRef.current && !profileBoxRef.current.contains(event.target)) {
//     setIsProfileOpen(false);
//   }
// };

// useEffect(() => {
//   document.addEventListener('click', handleClickOutside);
//   return () => {
//     document.removeEventListener('click', handleClickOutside);
//   };
// }, []);


const dropdownRef = useRef(null);

const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setShowDropdown(null);
  }
};

useEffect(() => {
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

useEffect(() => {
  setSearchText(searchVal != "null" ? searchVal : "")
},[searchVal])

  return (<div className={styles.navbar_main_container_wrapper}>
     <div className={styles.navbar_main_container}>
     <div>
    <div onClick={() => {
navigate("/")
    }} className={styles.breadcom_container}>
      <img src="../publicAssets/Logo/companyLogo.svg" alt="companyLogo" />
     </div>
    
    <div className={styles.menu_list}>
      <ul>
        {
          menuList.map((item) => {
            return <li key={item.name}>
              {
                item.child ? 
                <div style={{position:"relative"}}>
                  <p onClick={() => setShowDropdown(showDropdown ? null : item.name)}><span>{item.name}</span> <img style={{transform:showDropdown === item.name ? "rotate(180deg)"  : "rotate(0deg)"}} src="../publicAssets/utils/downarrow.svg" alt="downarrow"  /> </p>
                  <ul  ref={dropdownRef} style={{display:showDropdown === item.name ? "flex"  : "none"}} className={styles.child_container}>
                    {
                      item.child.map((item) => {
                        return <li key={item.name} onClick={() => {
                          navigate(item.url)
                          setShowDropdown(null)
                        }}>
                          <Link  to={item.url}>{item.name}</Link>
                        </li>
                      })
                    }
                  </ul>
                </div>
                
                : 
                <Link to={item.url}>{item.name}</Link>
              }
              </li>
          })
        }
        {
        isLoggedIn && <>
         <li>
              <Link to={"/register"}>{"Registration"}</Link>
              </li>
        <li onClick={() => {                localStorage.removeItem("userToken");
                window.location.href = "/"

              }}>Logout</li>
              
             

              </>
              }
      </ul>
    </div>
    </div>
     <div>
        <form onSubmit={(event) => {
             event.preventDefault();
             const parts = searchText.split(' in ');
             const jobPart = parts[0];
             const locationPart = parts[1];
         
             let finalSuggestion = searchText;
         
             // Check if the location part is a city, and update the input value to the corresponding state
             if (locationPart) {
               Object.entries(stateCity).forEach(([state, cities]) => {
                if (cities.map(city => city.toLowerCase()).includes(locationPart.toLowerCase())) {
                  finalSuggestion = `${jobPart} in ${state}`;
                }                
               });
             }

             //console.log("first",finalSuggestion)
             
             navigate(`/job/list?searchText=${finalSuggestion}&page=1`);
        }} className={styles.search_box}>
         <AutoSuggestJobs setFinalSuggestion={setFinalSuggestion} inputValue={searchText} setInputValue={setSearchText}/>
          <button>Search 
            <svg width="13" height="13" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.64792 16.2958C12.8717 16.2958 16.2958 12.8717 16.2958 8.64792C16.2958 4.42409 12.8717 1 8.64792 1C4.42409 1 1 4.42409 1 8.64792C1 12.8717 4.42409 16.2958 8.64792 16.2958Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M18.2093 18.2074L14.0508 14.0488" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </form>

        
      </div> 

      <div className={styles.registration}>
        <p>Get Personalized Healthcare Job Alerts </p>
        <button onClick={() => setShowRegistrations(true)}>Register
        Now</button>
      </div>
      

     </div>


     <MobileViewNav/>









  {
    showRegistrations && <RegistrationForm getClose={() => setShowRegistrations(false)}/>
  }

  

  </div>
  )
}

export default Navbar