import React from 'react'
import styles from "./index.module.css"
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select';
import { fields, states } from '.';

const SearchForm = () => {

    const [formData, setFormData] = useSearchParams({
        field: '',
        state: ''
    })

    console.log(formData.get('field'))

    return (
        <form onSubmit={(event) => {
            event.preventDefault();
            const formData = new FormData(event.target);
            const formDataJSON = Object.fromEntries(formData.entries());
            setFormData((prev) => {
                prev.set('field', formDataJSON.field);
                prev.set('state', formDataJSON.state);
                return prev;
            }, { replace: true });
        }} className={styles.container}>
            <Select
                className={styles.select}
                classNamePrefix="select"
                defaultValue={fields.find((item) => item.value === formData.get('field')) || { value: '', label: "Select Your Field" }}
                isSearchable={true}
                name="field"
                options={fields}
            />

            <Select
                className={styles.select}
                classNamePrefix="select"
                defaultValue={states.find((item) => item.value === formData.get('state')) || { value: '', label: "Select State" }}
                isSearchable={true}
                name="state"
                options={states}
            />
            <button>Apply & Search</button>
        </form>
    )
}

export default SearchForm