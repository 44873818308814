import React from 'react'
import styles from "./index.module.css"

const Disclaimer = ({ onClose }) => {
    return (
        <div onClick={onClose} className={styles.container_wrapper}>
            <div onClick={(event) => event.stopPropagation()} className={styles.container}>
                <h2>Disclaimer:</h2>
                <p>Job postings in this group are sourced from various external sources. We are not responsible or liable for the accuracy or reliability of the information provided. Never pay any fees for job applications or offers. Conduct your own due diligence to verify the legitimacy of employers and job opportunities. By using this group, you agree that we are not liable for any actions or outcomes related to these postings. Report any suspicious or fraudulent activities to the group administrators.</p>
                <div className={styles.action_buttons}>
                    <button>
                        <svg width="40" height="36" viewBox="0 0 40 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.875 35.75C8.01938 35.75 0 27.7306 0 17.875C0 8.01938 8.01938 0 17.875 0C21.3005 0 24.635 0.97825 27.5178 2.82588C28.0849 3.18988 28.249 3.94388 27.8866 4.50938C27.5226 5.07488 26.767 5.24063 26.2031 4.87663C23.712 3.2825 20.8341 2.4375 17.875 2.4375C9.36325 2.4375 2.4375 9.36325 2.4375 17.875C2.4375 26.3868 9.36325 33.3125 17.875 33.3125C26.3868 33.3125 33.3125 26.3868 33.3125 17.875C33.3125 17.3664 33.2881 16.8626 33.241 16.367C33.176 15.6975 33.6668 15.1011 34.3379 15.0378C35.0025 14.9516 35.6021 15.4635 35.6671 16.133C35.7224 16.7066 35.75 17.2868 35.75 17.875C35.75 27.7306 27.7306 35.75 17.875 35.75Z" fill="currentColor" />
                            <path d="M19.9075 18.2871L13.8098 12.1894C13.1384 11.518 12.0499 11.518 11.3785 12.1894L11.3785 12.1894C10.7072 12.8608 10.7072 13.9493 11.3785 14.6206L11.3785 14.6206L18.6898 21.9319C18.69 21.9321 18.6902 21.9323 18.6904 21.9325C19.026 22.27 19.4674 22.4371 19.9058 22.4371C20.3447 22.4371 20.7849 22.2699 21.1211 21.936L21.1223 21.9348L38.9973 4.05977C39.6687 3.38839 39.6687 2.29993 38.9973 1.62854V1.62854C38.3259 0.957153 37.2374 0.957154 36.566 1.62854L19.9075 18.2871Z" fill="currentColor" stroke="currentColor" />
                        </svg>
                        Yes, Proceed
                    </button>
                    <button onClick={onClose}>
                        <svg width="1" height="1" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.1893 13.8107C43.6452 20.2665 43.6452 30.7335 37.1893 37.1893C30.7335 43.6452 20.2665 43.6452 13.8107 37.1893C7.35482 30.7335 7.35482 20.2665 13.8107 13.8107C20.2665 7.35482 30.7335 7.35482 37.1893 13.8107Z" stroke="currentColor" stroke-width="3" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M30.9857 33.5388L18.2394 20.7925C17.5374 20.0906 17.5374 18.9455 18.2394 18.2434C18.9414 17.5414 20.0867 17.5413 20.7885 18.2433L33.5349 30.9896C34.2369 31.6915 34.2369 32.8366 33.5349 33.5386C32.8329 34.2406 31.6876 34.2408 30.9857 33.5388Z" fill="currentColor" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2394 30.9896L30.9857 18.2433C31.6876 17.5413 32.8327 17.5413 33.5347 18.2433C34.2367 18.9453 34.2369 20.0906 33.5349 20.7925L20.7885 33.5388C20.0867 34.2408 18.9416 34.2408 18.2395 33.5388C17.5375 32.8368 17.5374 31.6915 18.2394 30.9896Z" fill="currentColor" />
                        </svg>
                        No, Go Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer