import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/auth";
import messageReducer from "../slices/message";
import successMessageReducer from "../slices/successMessage";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  successMessage: successMessageReducer,
};

export const store = configureStore({
  reducer: reducer,
  devTools: true,
});
