import { createContext, useContext, useState } from "react";
import  axiosInstance from "./Interceptors"
import { toast } from 'react-toastify'


const LoadingContext = createContext()

export function useLoadingContext ()
{
    return useContext(LoadingContext)
}

export function LoadingProvider({children})
{
 

  const [loading,setLoading] = useState(false)

 
//   const notifySuccess = (message) => toast.success(message);
//   const notifyError = (message) => toast.error(message)





    return <LoadingContext.Provider
    value={{

      loading,
      setLoading

    }}>
        {children}
    </LoadingContext.Provider>
}