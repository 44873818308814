/* eslint-disable no-undef */
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use((config) => {
  // Add or modify the headers of the request
  config.headers["Authorization"] =
    "Bearer " + JSON.parse(localStorage.getItem("userToken"))?.refreshToken;
  //   config.headers['Content-Type'] = 'multipart/form-data'
  config.headers["Content-Security-Policy"] =
    "default-src 'self'; img-src 'self' data: blob:;";
  return config;
});

instance.interceptors.response.use((response) => {
  // Add or modify the response data
  response.data.modified = true;

  return response;
});

export default instance;
