import React from "react";
import styles from "./index.module.css";
import { useLocation } from "react-router";
import { tags as tags } from "./data";
import { Link } from "react-router-dom";

const ExploreTopGovernmentJob = () => {

  return (
    <div className={styles.explore_top_government_job_container_wrapper}>
      <div className={styles.layer}></div>
      {/* <div className={styles.explore_top_government_job_container}>
        <div className={styles.heading}>
          <h2>
            Search Job by  <span>Sector</span>
          </h2>
        </div>

        <div className={styles.content}>
          <Link to="/job/list?category=all&page=1">
            <div>
              <img
                src="../publicAssets/governmentJobHome/government.png"
                alt="government"
              />
              <h2>Government Jobs</h2>
            </div>
          </Link>
          <Link to="">
            <div>
              <img
                src="../publicAssets/governmentJobHome/clinic.png"
                alt="clinic"
              />
              <h2>Private Jobs</h2>
            </div>
          </Link>
        </div>

      </div> */}
      <div className={styles.explore_top_government_job_container}>
        <div className={styles.heading}>
          <h2>
            Search Job by <span>Education</span>
          </h2>
        </div>

        <div className={styles.content}>
          <Link to="/job/doctor?category=Doctor&page=1">
            {" "}
            <div>
              <img
                src="../publicAssets/governmentJobHome/doctor_icon.png"
                alt="doctor_icon"
              />
              <h2>Doctor</h2>
            </div>
          </Link>
          <Link to="/job/nursing?category=Nursing&page=1">
            <div>
              <img
                src="../publicAssets/governmentJobHome/nurse_icon.png"
                alt="nurse_icon"
              />
              <h2>Nursing</h2>
            </div>
          </Link>
          <Link to="/job/pharmacy?category=Pharmacy&page=1">
            <div>
              <img
                src="../publicAssets/governmentJobHome/clinic.png"
                alt="nurse_icon"
              />
              <h2>Pharmacy</h2>
            </div>
          </Link>
          <Link to="/job/technician?category=Technician&page=1">
            <div>
              <img
                src="../publicAssets/governmentJobHome/paramedic_icon.png"
                alt="paramedic_icon"
              />
              <h2>Technician</h2>
            </div>
          </Link>
        </div>

        <TagsContainer />
      </div>
    </div>
  );
};

export default ExploreTopGovernmentJob;

export const TagsContainer = ({ data }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const searchTexts = queryParams.get("searchText");
  const tag = queryParams.get("tag");

  const isActive = (item) => {
    return item === searchTexts?.toString() && tag ? styles.active : "";
  };

  return (
    <div className={styles.tagging_container}>
      {tags.map((item) => (
        <Link to={`/job/${item.value.toLowerCase()}?searchText=${item.value}&tag=true&page=1`}>
          <div
            key={item.label}
            className={`${styles.tagging_item} ${isActive(item.value)}`}
          >
            <p>{item.label}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};
