import React, { useEffect } from "react";
import styles from "./index.module.css";
import JoinGroupForNotification from "../joinGroupsForNotification";
import JoinUsForm from "../joinUsForm";
import JobContainer from "../jobContainer";
import { Link } from "react-router-dom";
import { useGovernmentJobContext } from "../../ApiIntegration/governmentJob";
import { useNavigate } from "react-router";

const LatestGovernmentJob = () => {
  const { getGovernmentJobList, governmentJobList } = useGovernmentJobContext();
  useEffect(() => {
    getGovernmentJobList("/v1/governmentjobpost/getgovernmentjoboverview", [
      {},
      {
        jobTitle: 1,
        jobDescription: 1,
        createdAt: 1,
        categoryText: 1,
        organization: 1,
        salary: 1,
        location: 1,
        numberOfVacancies: 1,
        category: 1,
        tags: 1,
      },
    ]);
  }, []);

  const navigate = useNavigate();
  return (
    <div className={styles.main_container_wrapper}>
      <div className={styles.main_container}>
        <div className={styles.heading}>
          <div></div>
          <h2>
            Latest <span>Government</span> Jobs
          </h2>
          <div></div>
        </div>

        <div className={styles.content}>
          <div className={styles.government_job_list}>
            {governmentJobList?.data?.map((item, index) => (
              <JobContainer key={index} keyValue={item._id} item={item} />
            ))}
          </div>
          <div className={styles.notification_container}>
            <JoinGroupForNotification style={{ marginTop: "2.4rem" }} />

            <JoinUsForm style={{ marginTop: "2rem" }} />
          </div>

          <div className={styles.view_more}>
            <Link to="/job/list?page=1">
              <button onClick={() => navigate("/job/list?page=1")}>
                View More
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div className={styles.mobile_view_notification}>
        {" "}
        <JoinGroupForNotification style={{ marginTop: "0" }} />
      </div>
    </div>
  );
};

export default LatestGovernmentJob;

export function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
}
