import React from 'react'
import styles from "./index.module.css"

const Textarea = ({
    name,
    label,
    placeholder,
    required,
    type,
    defaultValue
}) => {
    return (<div className={styles.container}>
        <p>{label} {required && <span>*</span>}</p>
        <textarea
            placeholder={placeholder}
            type={type}
            name={name}
            value={defaultValue}
            required={required}
        />
    </div>
    )
}

export default Textarea