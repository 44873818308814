import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const successMessageSlice = createSlice({
  name: "successMessage",
  initialState,
  reducers: {
    setSuccessMessage: (state, action) => {
      return { successMessage: action.payload };
    },
    clearSuccessMessage: () => {
      return { successMessage: "" };
    },
  },
});

const { reducer, actions } = successMessageSlice;

export const { setSuccessMessage, clearSuccessMessage } = actions;

export default reducer;
