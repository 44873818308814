import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import AuthService from "../services/auth.service";
import { setSuccessMessage } from "./successMessage";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "auth/register",
  async ({ name, emailOrPhoneNumber, password, role }, thunkAPI) => {
    try {
      const response = await AuthService.register(
        name,
        emailOrPhoneNumber,
        password,
        role
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.error) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const verifyEmail = createAsyncThunk(
  "auth/verify-email",
  async ({ token, type }, thunkAPI) => {
    try {
      const response = await AuthService.verifyEmail(token, type);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verify-otp",
  async (phoneNumber, thunkAPI) => {
    try {
      const response = await AuthService.verifyOtp(phoneNumber);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createPassword = createAsyncThunk(
  "auth/create-password",
  async (password, thunkAPI) => {
    try {
      const response = await AuthService.createPassword(password);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ password, emailOrPhoneNumber }, thunkAPI) => {
    try {
      const response = await AuthService.login(password, emailOrPhoneNumber);
      //console.log(response.data)
      thunkAPI.dispatch(setSuccessMessage(response.data.data.message));
      return response.data;
    } catch (error) {
      //console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.error) ||
        error.error ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const loggedInUser = createAsyncThunk(
  "auth/loggedin",
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.loggedInUser();
      // //console.log(response.data)
      thunkAPI.dispatch(setSuccessMessage(response.data.data.message));
      return response.data;
    } catch (error) {
      //console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.error) ||
        error.error ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forgotpassword = createAsyncThunk(
  "auth/forgotpassword",
  async ({ password, emailOrPhoneNumber }, thunkAPI) => {
    try {
      const response = await AuthService.forgotPassword(emailOrPhoneNumber);
      // //console.log(response.data)
      thunkAPI.dispatch(setSuccessMessage(response.data.data.message));
      return response.data;
    } catch (error) {
      //console.log(error)
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.error) ||
        error.error ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        // Handle successful registration
        state.isLoggedIn = false;
      })
      .addCase(register.rejected, (state, action) => {
        // Handle registration failure
        state.isLoggedIn = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        // Handle successful login
        state.isLoggedIn = true;
        state.user = action.payload.data.user;
      })
      .addCase(login.rejected, (state, action) => {
        // Handle login failure
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state, action) => {
        // Handle successful logout
        state.isLoggedIn = false;
        state.user = null;
      })
      .addCase(loggedInUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload.data.user;
      })
      .addCase(loggedInUser.rejected, (state, action) => {
        // Handle login failure
        state.isLoggedIn = false;
        state.user = null;
      });
  },
});
const { reducer } = authSlice;
export default reducer;
