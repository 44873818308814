import React, { useState } from 'react'
import styles from "./index.module.css"
import TextInput from '../../Components/InputTags/TextInput';
import ReactSelect from 'react-select';
import Button from '../../Components/InputTags/Button';
import cityState from "../../data/cityState.json"
import Textarea from '../../Components/InputTags/Textarea';
import { fields } from '../../Components/searchForm';
import { tags } from '../../data/tags';
import { usePrivateJobContext } from '../../../../ApiIntegration/privatejobPosting';
import { useNavigate } from 'react-router';

const JobForm = () => {

    const { postPatchPrivateJobInfo } = usePrivateJobContext()
    const [city, setCity] = useState([
        { value: " ", label: "Please Select State First" }
    ])
    const [selectedTags, setSelectedTags] = useState([]);
    const navigate = useNavigate()

    return (<div className={styles.container_wrapper}>
        <div className={styles.container}>
            <div className={styles.heading}>
                <h1>Post a Job in Seconds</h1>
                <h2>You are just a few steps away from next best hire</h2>
                <p>Lets get started</p>
            </div>
            <div className={styles.body}>
                <h2>Job Details</h2>
                <form onSubmit={(event) => {
                    event.preventDefault();
                    const formData = new FormData(event.target);
                    const formDataJSON = Object.fromEntries(formData.entries());
                    formDataJSON.tags = selectedTags
                    formDataJSON.url = formDataJSON.jobTitle.trim().toLowerCase().replace(/\s+/g, '-');
                    console.log(formDataJSON)
                    postPatchPrivateJobInfo("/v1/privatejobpost/postprivatejob", formDataJSON, () => navigate("/private-job/listing"))

                }} action="">
                    <TextInput
                        label={"Company Name"}
                        placeholder={"Company Name"}
                        name="companyName"
                        required={true}
                        type={"text"}
                    />
                    <TextInput
                        label={"Job Title"}
                        // placeholder={"Job Title"}
                        name="jobTitle"
                        required={true}
                        type={"text"}
                    />
                    {/* <TextInput
                        label={"Position Name"}
                        // placeholder={"Position Name"}
                        name="positionName"
                        required={true}
                        type={"text"}
                    /> */}
                    <TextInput
                        label={"No. of Vacancies Available"}
                        // placeholder={"No. of Vacancies Available*"}
                        name="numberOfVacancy"
                        required={true}
                        type={"text"}
                    />
                    <div className={styles.select_field}>
                        <p>{"Field"} <span>*</span></p>
                        <ReactSelect
                            className={styles.select}
                            classNamePrefix="select"
                            defaultValue={{ value: '', label: "Select Field" }}
                            isSearchable={true}
                            name="field"
                            options={fields}
                        />
                    </div>
                    <div className={styles.fieldset}>
                        <p>{"Location"} <span>*</span></p>
                        <div className={styles.grid}>
                            <ReactSelect

                                options={[{ value: "", label: "Select State" }, ...(Object.keys(cityState || {}).map((state) => {
                                    return { value: state.toLowerCase(), label: state }
                                }))]}
                                onChange={(data) => setCity(cityState?.[data.label]?.map((city) => {
                                    return { value: city.toLowerCase(), label: city }
                                }))}
                                defaultValue={{ value: "", label: "Select State" }}
                                name='jobState'
                                Required={true}
                            />

                            <ReactSelect
                                options={[{ value: "", label: "Select City" }, ...city]}
                                defaultValue={{ value: "", label: "Select City" }}
                                name='jobCity'
                                Required={true}
                            />
                        </div>

                    </div>

                    <Textarea
                        label={"Responsibilities"}
                        // placeholder={"No. of Vacancies Available*"}
                        name="responsibilities"
                        required={true}
                        type={"textarea"}
                    />

                    <Textarea
                        label={"Qualifications"}
                        // placeholder={"No. of Vacancies Available*"}
                        name="qualification"
                        required={true}
                        type={"textarea"}
                    />

                    <TextInput
                        label={"How to Apply"}
                        // placeholder={"No. of Vacancies Available*"}
                        name="howToApply"
                        required={false}
                        type={"text"}
                    />

                    <div className={styles.select_field}>
                        <p>{"Tags"} <span>*</span></p>
                        <ReactSelect
                            className={styles.select}
                            classNamePrefix="select"
                            onChange={(data) => setSelectedTags(data.map(data => data.value))}
                            // defaultValue={{ value: '', label: "Select Tags" }}
                            isSearchable={true}
                            name="tags"
                            options={tags}
                            isMulti
                        />
                    </div>


                    <Button
                        label={"Submit"}
                        type={"submit"}
                    />
                </form>
            </div>
        </div>
    </div>
    )
}

export default JobForm