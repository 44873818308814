import Routers from "./Routers/Routers";
import "./App.css";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loggedInUser } from "./slices/auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoadingContext } from "./ApiIntegration/loading";
import { useAxiosInterceptors } from "./ApiIntegration/Interceptors";

function App() {
  useAxiosInterceptors();
  const dispatch = useDispatch();

  const { loading } = useLoadingContext();

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      dispatch(loggedInUser())
        .unwrap()
        .then((response) => {
          const { accessToken, refreshToken } = response.data;
          localStorage.setItem(
            "userToken",
            JSON.stringify({ accessToken, refreshToken })
          );
        })
        .catch((error) => {
          //console.log(error)
        });
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="loading_container">
          <img
            src="../publicAssets/companyLogo.jpg"
            alt="loading_icon"
            srcSet=""
          />
        </div>
      )}
      <Routers />

      <ToastContainer className="alert_container" position="top-right" />
    </>
  );
}

export default App;
