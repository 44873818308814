import React, { useState } from "react";
import styles from "./index.module.css";
import { axiosInstance } from "../../ApiIntegration/Interceptors";
import { toast } from "react-toastify";

const JobNotificationPopUp = ({ getClose, data }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    specialties: "",
  });

  const [loading, setLoading] = useState(false);

  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleChange = (event) => {
    let value = event.target.value;
    let key = event.target.name;
    setFormData({ ...formData, [key]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    axiosInstance
      .post(`/v1/contact/contacts`, formData)
      .then((response) => {
        notifySuccess(response.data.message);
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          specialties: "",
        });
      })
      .catch((error) => {
        notifyError(error.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={styles.registration_pop_up_container}>
      <div className={styles.container}>
        <h3>
          {data.icon === "whatsapp" ? (
            <svg
              width="25"
              height="25"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M53.1261 9.20566C47.9154 3.94302 40.9957 0.715606 33.615 0.105585C26.2343 -0.504437 18.8786 1.54309 12.8746 5.87883C6.87067 10.2146 2.61369 16.5531 0.871558 23.7511C-0.870571 30.9491 0.01684 38.5328 3.37372 45.1341L0.0785229 61.132C0.0443317 61.2913 0.0433633 61.4558 0.0756789 61.6155C0.107995 61.7751 0.172898 61.9263 0.266331 62.0597C0.403204 62.2622 0.598582 62.418 0.826373 62.5065C1.05416 62.595 1.30353 62.6118 1.54116 62.5548L17.2204 58.8385C23.8032 62.1104 31.3333 62.9407 38.4708 61.1818C45.6083 59.4228 51.8904 55.1887 56.1991 49.2327C60.5078 43.2768 62.5637 35.9853 62.001 28.6558C61.4382 21.3263 58.2934 14.4341 53.1261 9.20566ZM48.2373 48.2244C44.6321 51.8195 39.9896 54.1927 34.9641 55.0095C29.9387 55.8264 24.7836 55.0456 20.2253 52.7774L18.0399 51.6961L8.42749 53.9725L8.45595 53.853L10.4479 44.178L9.37792 42.0666C7.04886 37.4924 6.22725 32.2985 7.0308 27.2288C7.83434 22.1591 10.2218 17.4737 13.8512 13.844C18.4116 9.28502 24.5959 6.72393 31.0443 6.72393C37.4926 6.72393 43.677 9.28502 48.2373 13.844C48.2762 13.8885 48.318 13.9304 48.3626 13.9692C52.8664 18.5399 55.3807 24.7058 55.3572 31.1226C55.3338 37.5394 52.7745 43.6868 48.2373 48.2244Z"
                fill="#40A133"
              />
              <path
                d="M47.3635 41.1231C46.1854 42.9785 44.3244 45.2493 41.9853 45.8127C37.8877 46.8029 31.5989 45.8468 23.7735 38.5507L23.6768 38.4654C16.7961 32.0855 15.0091 26.7757 15.4416 22.5642C15.6807 20.1739 17.6726 18.0112 19.3515 16.5998C19.6169 16.3733 19.9317 16.212 20.2706 16.1289C20.6095 16.0457 20.9632 16.043 21.3033 16.121C21.6434 16.199 21.9606 16.3555 22.2294 16.578C22.4983 16.8004 22.7113 17.0827 22.8516 17.4023L25.3841 23.0935C25.5487 23.4625 25.6097 23.8693 25.5606 24.2704C25.5114 24.6715 25.3541 25.0516 25.1053 25.3699L23.8248 27.0318C23.55 27.3749 23.3842 27.7924 23.3487 28.2306C23.3132 28.6687 23.4096 29.1075 23.6256 29.4904C24.3427 30.7481 26.0614 32.5978 27.9679 34.3108C30.1078 36.2458 32.4811 38.0158 33.9835 38.619C34.3856 38.7833 34.8276 38.8233 35.2526 38.7341C35.6776 38.6448 36.0661 38.4303 36.3681 38.1182L37.8535 36.6214C38.1401 36.3388 38.4966 36.1372 38.8865 36.0372C39.2764 35.9373 39.6858 35.9425 40.0731 36.0523L46.0887 37.7597C46.4205 37.8614 46.7247 38.0378 46.9779 38.2751C47.2311 38.5125 47.4267 38.8047 47.5498 39.1292C47.6728 39.4538 47.7199 39.8022 47.6877 40.1478C47.6554 40.4934 47.5445 40.827 47.3635 41.1231Z"
                fill="#40A133"
              />
            </svg>
          ) : (
            <svg
              width="25"
              height="25"
              viewBox="0 0 62 62"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1727_6627)">
                <path
                  d="M31 62C48.1208 62 62 48.1208 62 31C62 13.8792 48.1208 0 31 0C13.8792 0 0 13.8792 0 31C0 48.1208 13.8792 62 31 62Z"
                  fill="#039BE5"
                />
                <path
                  d="M14.1852 30.3278L44.0747 18.8033C45.4625 18.3019 46.6734 19.1411 46.2241 21.2397L46.2266 21.2372L41.137 45.2126C40.7604 46.9128 39.7493 47.3257 38.3361 46.5253L30.5861 40.8133L26.8479 44.4146C26.435 44.8275 26.0863 45.1763 25.2846 45.1763L25.8344 37.2894L40.1973 24.313C40.8222 23.7633 40.0581 23.4533 39.2334 24.0006L21.4835 35.1763L13.8316 32.7896C12.1702 32.2628 12.1338 31.1282 14.1827 30.3278H14.1852Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1727_6627">
                  <rect width="62" height="62" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          {data.heading}
        </h3>
        <span onClick={getClose} className={styles.cut_option}>
          X
        </span>
        <ul>
          {data.data.map((item) => (
            <li key={item.name}>
              <p>{item.name}</p>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                Join Group
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default JobNotificationPopUp;
