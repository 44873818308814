// axiosInstance.js
import axios from 'axios';

import { useLoadingContext } from './loading';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

const useAxiosInterceptors = () => {
  const { setLoading } = useLoadingContext();

  axiosInstance.interceptors.request.use(config => {
    setLoading(true);
    config.headers['Authorization'] = 'Bearer ' + JSON.parse(localStorage.getItem('userToken'))?.refreshToken;
    config.headers['Content-Security-Policy'] = "default-src 'self'; img-src 'self' data: blob:;";
    return config;
  }, error => {
    setLoading(false);
    return Promise.reject(error);
  });

  axiosInstance.interceptors.response.use(response => {
    setLoading(false);
    response.data.modified = true;
    return response;
  }, error => {
    setLoading(false);
    return Promise.reject(error);
  });
};

export { axiosInstance, useAxiosInterceptors };
