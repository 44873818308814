import React, { useEffect, useRef, useState } from 'react'
import styles from "./index.module.css"
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';
import { usePrivateJobContext } from '../../../../ApiIntegration/privatejobPosting';
import SearchForm from '../../Components/searchForm/index.jsx';
import JoinGroupForNotification from '../../../../components/joinGroupsForNotification';
import JoinUsForm from '../../../../components/joinUsForm';
import { sortByData } from '../..';
import JobContainer from '../../Components/jobContainer';
import Select from 'react-select'
import ReactPaginate from 'react-paginate'
import Disclaimer from '../../Components/Desclaimer/index.jsx';

const JobListing = () => {



    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const category = queryParams.get("category") || "";
    const searchText = queryParams.get("searchText") || "";

    const initialPageNumber =
        parseInt(new URLSearchParams(window.location.search).get("page")) || 1;
    const [currentPage, setCurrentPage] = useState(initialPageNumber);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [showFilterOption, setShowFilterOption] = useState(false);
    const [showDisclaimer, setShowDisclaimer] = useState(false)
    const { user, isLoggedIn } = useSelector((state) => state.auth);
    const dropdownRef = useRef(null);
    const isSmallScreen = useMediaQuery({ query: "(max-width: 768px)" });
    const [pageRangeDisplayed, setPageRangeDisplayed] = useState(4);
    const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(2);

    const [filters, setFilters] = useSearchParams({
        sortOrder: 'dsc',
        category: 'all',
        tab: "1",
        page: "1"
    })


    const { getPrivateJobList, privateJobList } = usePrivateJobContext();

    console.log(privateJobList);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowFilterOption(false);
        }
    };

    const handlePageClick = (event) => {
        const selectedPage = event.selected + 1;
        if (selectedPage != currentPage) {
            const newUrl = new URL(window.location);
            newUrl.searchParams.set("page", selectedPage);
            window.history.pushState({}, "", newUrl);
            setCurrentPage(selectedPage);
        }
        // Optionally, update the URL or trigger any side effects here
    };

    useEffect(() => {
        if (isSmallScreen) {
            setPageRangeDisplayed(1);
            setMarginPagesDisplayed(0);
        } else {
            setPageRangeDisplayed(3);
            setMarginPagesDisplayed(2);
        }
    }, [isSmallScreen]);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const url = `/v1/privatejobpost/getprivatejoblist`
        getPrivateJobList(url,
            [{},
            {
                jobTitle: 1,
                companyName: 1,
                createdAt: 1,
                numberOfVacancy: 1,
                jobState: 1,
                jobCity: 1,
                responsibilities: 1,
                field: 1,
                tags: 1,
            }]
        )
    }, [])




    return (
        <div className={styles.container}>
            <div className={styles.left_container}>
                <div> <SearchForm /></div>
                <div><JoinGroupForNotification /><JoinUsForm style={{ marginTop: "0.8rem" }} /></div>

            </div>
            <div className={styles.right_container}>
                <div className={styles.filters}>
                    <div>
                        <p>
                            Showing {parseInt((currentPage - 1) * itemsPerPage)} -{" "}
                            {parseInt(currentPage * itemsPerPage) >
                                // governmentJobList.totalDocuments
                                10
                                ? 11
                                //    governmentJobList.totalDocuments
                                : parseInt(currentPage * itemsPerPage)}{" "}
                            of {0} Results
                        </p>
                        <div ref={dropdownRef} className={styles.filter_body}>
                            <h1 onClick={() => setShowFilterOption(!showFilterOption)}>
                                {" "}
                                {category === "all"
                                    ? "Doctor, Medical Job..."
                                    : category || searchText || " Doctor, Medical Job..."}{" "}
                                <span>
                                    <svg
                                        style={{
                                            transform: showFilterOption
                                                ? "rotate(180deg)"
                                                : "rotate(0deg)",
                                        }}
                                        width="12"
                                        height="12"
                                        viewBox="0 0 27 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M16.9641 22C15.4245 24.6667 11.5755 24.6667 10.0359 22L0.942634 6.25001C-0.596966 3.58334 1.32753 0.250001 4.40673 0.250001L22.5933 0.250003C25.6725 0.250003 27.597 3.58334 26.0574 6.25L16.9641 22Z"
                                            fill="black"
                                        />
                                    </svg>
                                </span>
                            </h1>
                            {showFilterOption && (
                                <div className={styles.filter_item}>
                                    <h3
                                        onClick={() => {
                                            navigate(
                                                `/job/doctor?category=Doctor&page=1&order=${""}&tab=${""}`
                                            );

                                            setShowFilterOption(false);
                                        }}
                                    >
                                        Doctor
                                    </h3>
                                    <h3
                                        onClick={() => {
                                            navigate(
                                                `/job/nursing?category=Nursing&page=1&order=${""}&tab=${""}`
                                            );

                                            setShowFilterOption(false);
                                        }}
                                    >
                                        Nursing
                                    </h3>
                                    <h3
                                        onClick={() => {
                                            navigate(
                                                `/job/paramedical?category=Paramedical&page=1&order=${""}&tab=${""}`
                                            );

                                            setShowFilterOption(false);
                                        }}
                                    >
                                        Paramedical
                                    </h3>
                                </div>
                            )}
                        </div>
                    </div>
                    <button onClick={() => setShowDisclaimer(true)} className={styles.switch_button}>Switch to Private Jobs</button>
                    <div className={styles.sort_by_container}>
                        <label htmlFor="">Sort By : </label>
                        <Select
                            classNamePrefix="select"
                            onChange={(data) => {
                                console.log(data);
                                setFilters((prev) => {
                                    prev.set('sortOrder', data.value)
                                    return prev;
                                })
                            }}
                            name="sortOrder"
                            defaultValue={sortByData.find((item) => item.value === filters.get('sortOrder')) ||
                                { value: "dsc", label: "Latest" }
                            }
                            options={sortByData}
                        >
                        </Select>
                    </div>
                </div>

                <div className={styles.job_listing_container}>


                    {privateJobList?.data?.map((item, index) => (
                        <JobContainer key={index} keyValue={item._id} item={item} />
                    ))}
                    {privateJobList?.data?.length === 0 && (
                        <div className={styles.empty_container}>
                            <img
                                style={{ maxWidth: "20rem" }}
                                src="../publicAssets/Dashboard/empty.png"
                                alt="empty icon"
                            />
                            <h2>Not Found</h2>
                        </div>
                    )}
                    {privateJobList?.data?.length !== 0 && (
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(privateJobList.totalPages || 0)}
                            marginPagesDisplayed={marginPagesDisplayed}
                            pageRangeDisplayed={pageRangeDisplayed}
                            onPageChange={handlePageClick}
                            containerClassName={`${styles.pagination} pagination`}
                            activeClassName={styles.active}
                            forcePage={Math.min(
                                Math.max(currentPage - 1, 0),
                                Math.ceil(privateJobList.totalPages || 0) - 1
                            )} // Ensure forcePage is within bounds
                        />
                    )}










                </div>
            </div>
            {
                showDisclaimer && <Disclaimer onClose={() => setShowDisclaimer(false)} />
            }
        </div>
    )
}

export default JobListing