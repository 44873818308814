import React from "react";
import styles from "./index.module.css";
import JoinUsForm from "../../components/joinUsForm";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={styles.footer_main_container_wrapper}>
      <div className={styles.mobile_view_join_form}>
        <JoinUsForm style={{ maxWidth: "320px" }} />
      </div>
      <div className={styles.footer_main_container}>
        <div className={styles.footer_body}>
          <div className={styles.company_social}>
            <svg
              width="226"
              height="47"
              viewBox="0 0 226 47"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_483_8470)">
                <path
                  d="M0 9.7771H7.39409L13.9069 27.6447C14.7194 29.9696 15.4632 32.4308 16.2758 34.8239H16.5008C17.3133 32.4308 18.0133 29.9696 18.8196 27.6447L25.2012 9.7771H32.5953V46.3368H26.6012V29.61C26.6012 26.2311 27.12 21.3706 27.4325 17.9855H27.2075L24.2324 26.4791L18.1696 42.9022H14.2944L8.20663 26.4791L5.27525 17.9855H5.05023C5.36275 21.3706 5.88152 26.2373 5.88152 29.61V46.3368H0V9.7771Z"
                  fill="white"
                />
                <path
                  d="M38.2461 32.6229C38.2461 23.7015 44.4901 18.2024 51.0029 18.2024C58.4845 18.2024 62.4347 23.5713 62.4347 31.2404C62.4347 32.4679 62.2972 33.7017 62.1535 34.3712H44.5839C45.1277 39.2442 48.2841 42.0155 52.7468 42.0155C55.1156 42.0155 57.1407 41.2777 59.1721 40.0254L61.3784 44.0304C58.7408 45.7973 55.4031 47.0062 51.8905 47.0062C44.2464 47.0062 38.2523 41.6869 38.2523 32.6291L38.2461 32.6229ZM56.822 29.988C56.822 25.7598 54.9281 23.1932 51.1404 23.1932C47.9153 23.1932 45.0777 25.5862 44.5151 29.988H56.822Z"
                  fill="white"
                />
                <path
                  d="M65.6016 32.623C65.6016 23.6768 71.2581 18.2025 77.3209 18.2025L84.3337 18.4071V9.7771H90.8277V46.3368H85.5087C85.5087 46.3368 79.9397 46.3864 76.9646 46.3864C70.0643 46.3864 65.6016 41.687 65.6016 32.6292V32.623ZM84.3337 41.6932V23.9434C84.3337 23.9434 80.571 23.5219 78.6959 23.5219C75.177 23.5219 72.2706 26.8325 72.2706 32.5548C72.2706 38.2772 74.5707 41.6746 78.5584 41.6746C80.7023 41.6746 84.3274 41.687 84.3274 41.687L84.3337 41.6932Z"
                  fill="white"
                />
                <path
                  d="M90.7852 9.7771H98.1793L104.692 27.6447C105.505 29.9696 106.248 32.4308 107.061 34.8239H107.286C108.098 32.4308 108.798 29.9696 109.605 27.6447L115.986 9.7771H123.38V46.3368H117.386V29.61C117.386 26.2311 117.905 21.3706 118.218 17.9855H117.993L115.018 26.4791L108.955 42.9022H105.08L98.9918 26.4791L96.0604 17.9855H95.8354C96.1479 21.3706 96.6667 26.2373 96.6667 29.61V46.3368H90.7852V9.7771Z"
                  fill="white"
                />
                <path
                  d="M151.713 18.8721H157.032C157.032 18.8721 163.008 18.8225 166.795 18.8225C172.727 18.8225 175.314 22.1828 175.314 29.114V46.3306H168.82V29.9386C168.82 25.419 167.514 23.7203 164.514 23.7203C162.076 23.7203 158.201 24.0427 158.201 24.0427V46.3244H151.707V18.8721H151.713Z"
                  fill="white"
                />
                <path
                  d="M177.598 32.6229C177.598 23.4783 184.11 18.2024 191.486 18.2024C195.005 18.2024 197.574 19.5415 199.58 21.2898L196.424 25.4685C194.961 24.1913 193.561 23.4535 191.823 23.4535C187.361 23.4535 184.273 27.1176 184.273 32.6229C184.273 38.1283 187.317 41.7427 191.623 41.7427C193.786 41.7427 195.773 40.6949 197.349 39.3744L199.986 43.6212C197.392 45.9027 194.105 47 190.948 47C183.398 47 177.604 41.7241 177.604 32.6229H177.598Z"
                  fill="white"
                />
                <path
                  d="M202.229 9.32446H208.723V18.3016C208.723 18.3016 213.523 18.2024 217.311 18.2024C223.242 18.2024 225.83 22.1827 225.83 29.1139V46.3305H219.336V29.9385C219.336 25.4189 218.03 23.7202 215.029 23.7202C212.598 23.7202 208.717 24.0426 208.717 24.0426V46.3243H202.223V9.32446H202.229Z"
                  fill="white"
                />
                <path
                  d="M141.859 28.4443C141.615 25.7846 140.34 23.6767 136.871 23.6767C134.121 23.6767 131.527 24.9042 129.095 26.3798L126.708 22.0896C129.752 20.1863 133.671 18.4875 138.071 18.4875C144.99 18.4875 148.396 22.7158 148.396 30.36V46.6156H143.077C143.077 46.6156 137.083 46.6652 133.814 46.6652C128.97 46.6652 125.633 44.018 125.633 39.238C125.633 33.5032 130.308 30.4468 141.084 29.1758L141.227 33.1747C134.246 34.1418 131.896 36.0575 131.896 38.742C131.896 41.1661 133.564 42.2324 135.883 42.2324C138.202 42.2324 141.902 41.7365 141.902 41.7365V33.5156L141.865 28.4443H141.859Z"
                  fill="white"
                />
                <path
                  d="M210.563 7.91703L205.644 7.83023L200.719 7.74344L205.775 0L210.563 7.91703Z"
                  fill="white"
                />
                <path
                  d="M207.258 44.9913H203.508V43.7513H207.258V44.9913ZM207.258 42.0154H203.508V40.7755H207.258V42.0154ZM207.258 39.0395H203.508V37.7996H207.258V39.0395ZM207.258 36.0575H203.508V34.8175H207.258V36.0575ZM207.258 33.0878H203.508V31.8479H207.258V33.0878ZM207.258 30.112H203.508V28.872H207.258V30.112ZM207.258 27.1361H203.508V25.8962H207.258V27.1361ZM207.258 24.1602H203.508V22.9203H207.258V24.1602ZM207.258 21.1782H203.508V19.9382H207.258V21.1782ZM207.258 18.2085H203.508V16.9686H207.258V18.2085ZM207.258 15.2326H203.508V13.9927H207.258V15.2326ZM207.258 12.2568H203.508V11.0168H207.258V12.2568Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_483_8470">
                  <rect width="225.829" height="47" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>
              <a
                href="https://www.instagram.com/medmanchnursing?igsh=aHpjYWhtdm4yenZu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="50"
                    height="50"
                    rx="15"
                    fill="white"
                    fillOpacity="0.1"
                  />
                  <path
                    d="M33.3906 8.34033H16.6901C12.0784 8.34033 8.33984 12.0789 8.33984 16.6906V33.3911C8.33984 38.0028 12.0784 41.7413 16.6901 41.7413H33.3906C38.0023 41.7413 41.7409 38.0028 41.7409 33.3911V16.6906C41.7409 12.0789 38.0023 8.34033 33.3906 8.34033Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M31.7218 23.9884C31.9279 25.3783 31.6905 26.7978 31.0433 28.045C30.3962 29.2922 29.3722 30.3036 28.1172 30.9353C26.8621 31.567 25.4398 31.7869 24.0525 31.5637C22.6653 31.3404 21.3837 30.6855 20.3902 29.6919C19.3966 28.6984 18.7417 27.4168 18.5184 26.0296C18.2952 24.6423 18.5151 23.22 19.1468 21.9649C19.7785 20.7099 20.7899 19.6859 22.0371 19.0388C23.2843 18.3916 24.7038 18.1542 26.0937 18.3603C27.5114 18.5706 28.824 19.2312 29.8374 20.2447C30.8509 21.2581 31.5115 22.5707 31.7218 23.9884Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M34.2266 15.8555H34.2429"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/company/medmanch/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  data-supported-dps="24x24"
                  fill="white"
                  className="mercado-match"
                  width="30"
                  height="30"
                  focusable="false"
                >
                  <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                </svg>
              </a>
            </div>
          </div>

          <div className={styles.menu_list}>
            <h2>Jobs Category</h2>
            <ul>
              <li>
                <Link to="/job/nursing?category=Nursing&page=1"> Nursing Job</Link>
              </li>
              <li>
                <Link to="/job/doctor?category=Doctor&page=1">Doctor Job</Link>
              </li>
              <li>
                <Link to="/job/paramedical?category=Paramedic&page=1">
                  Paramedical Job
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.menu_list}>
            <h2>Jobs Cities</h2>
            <ul>
              {["Haryana", "Delhi", "Kerala", "Maharashtra", "Punjab"].map(
                (item) => (
                  <li key={item}>
                    <Link to={`/job/list?location=${item}&page=1`}>{item}</Link>
                  </li>
                )
              )}
            </ul>
          </div>

          <div className={styles.menu_list}>
            <h2>Contact Us</h2>
            <ul>
              <li>
                <a href="mailto:contact@medmanch.in">contact@medmanch.com</a>
              </li>
            </ul>
          </div>

          <div className={`${styles.menu_list} ${styles.menu_list_last}`}>
            <ul>
              <li>
                <Link to="/policy/4">Disclaimer</Link>
              </li>
              <li>
                {" "}
                <Link to={"/policy/2"}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={"/policy/1"}>Terms & Conditions</Link>
              </li>
              <li>
                <Link to={"/policy/3"}>Fraud alert</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className={styles.copyright}>
          <p>Copyrights 2024 MedManch All Right Reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
