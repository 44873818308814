import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./store/store.js";
import { GovernmentJobProvider } from "./ApiIntegration/governmentJob.js";
import { LoadingProvider } from "./ApiIntegration/loading.js";
import { BrowserRouter } from "react-router-dom";
import { PrivateJobProvider } from "./ApiIntegration/privatejobPosting.js";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <GovernmentJobProvider>
          <LoadingProvider>
            <PrivateJobProvider>
              <App />
            </PrivateJobProvider>
          </LoadingProvider>
        </GovernmentJobProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
