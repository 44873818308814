import axios from "axios";
import axiosInstance from "./interceptors.js";

const register = (name, emailOrPhoneNumber, password, role) => {
  return axiosInstance.post("v1/user/register", {
    name,
    emailOrPhoneNumber,
    password,
    role,
  });
};

const verifyEmail = (token, type) => {
  return axiosInstance.post(`v1/user/verify-email/${token}?type=${type}`);
};

const verifyOtp = (phoneNumber) => {
  return axiosInstance.post(`v1/user/verify-otp-registration`, {
    phoneNumber,
  });
};

const createPassword = (password) => {
  return axiosInstance.post("v1/user/create-password", {
    password,
  });
};

const login = (password, emailOrPhoneNumber) => {
  return axiosInstance.post("v1/user/login", {
    password,
    emailOrPhoneNumber,
  });
};

const loggedInUser = () => {
  return axiosInstance.get("v1/user/loggedin");
};

const forgotPassword = (emailOrPhoneNumber) => {
  return axiosInstance.post("v1/user/forget-password", {
    emailOrPhoneNumber,
  });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post("signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  verifyEmail,
  login,
  logout,
  getCurrentUser,
  createPassword,
  verifyOtp,
  forgotPassword,
  loggedInUser,
};

export default AuthService;
