import React, { useEffect, useRef, useState } from 'react'
import styles from "./index.module.css"
import { Navbar } from '../../layout'
import JoinGroupForNotification from '../../components/joinGroupsForNotification'
import JoinUsForm from '../../components/joinUsForm'
import { TagsContainer } from '../../components/exploreTopGovernmentJob'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import Disclaimer from './Components/Desclaimer'
import SearchForm from './Components/searchForm/index.jsx'
import Select from 'react-select'
import { sortByData } from './index.js'
import { useSearchParams } from 'react-router-dom'
import { usePrivateJobContext } from '../../ApiIntegration/privatejobPosting.js'
import ReactPaginate from 'react-paginate'
import { useMediaQuery } from 'react-responsive'
import JobContainer from './Components/jobContainer/index.jsx'
import JobListing from './Pages/JobListing/index.jsx'
import JobForm from './Pages/JobForm/index.jsx'

const PrivateJobPosting = () => {

    const {tab} = useParams()


    return (<div className={styles.container_wrapper}>
        <Navbar />

        <div className={styles.heading}></div>
        
        {
            tab === "listing" && <JobListing/>
        }
        {
            tab === "postjob" && <JobForm/>
        }
        {/* <JobListing/> */}
    </div>
    )
}


export default PrivateJobPosting