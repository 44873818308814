import { createContext, useContext, useState } from "react";
import  {axiosInstance} from "./Interceptors"
import { toast } from 'react-toastify'


const GovernmentJobContext = createContext()

export function useGovernmentJobContext ()
{
    return useContext(GovernmentJobContext)
}

export function GovernmentJobProvider({children})
{
 
  const [governmentJobInfo,setGovernmentJobInfo] = useState({})
  const [governmentJobList,setGovernmentJobList] = useState([])
  const [successMessage,setSuccessMessage] = useState(null)
  const [errorMessage,setErrorMessage] = useState(null)
  const [loading,setLoading] = useState(false)

 
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message)

    
  const getGovernmentJobInfo = (url,setData,orderAndTransformData,getSimilarPost) => {

    setLoading(true)

    axiosInstance.get(url)
    .then ((response) => {
        setData && setData(response.data.data)
      setGovernmentJobInfo(orderAndTransformData(response.data.data));
      getSimilarPost && getSimilarPost(response.data.data.category)
    })
    .catch ((error) => {
      setErrorMessage(error.response.data.message)
      notifyError(error.response.data.message)
    })
    .finally (()=>{
     setLoading(false)
    })

  }


  const getGovernmentJobList = (url,data,setData) => {

    setLoading(true)

    axiosInstance.post(url,data)
    .then ((response) => {
        setData && setData(response.data)
        setGovernmentJobList(response.data)
    })
    .catch ((error) => {
      setErrorMessage(error.response.data.message)
      notifyError(error.response.data.message)
    })
    .finally (()=>{
     setLoading(false)
    })

  }


  const postPatchGovernmentJobInfo = (url,data,getClose,refreshFunction) => {

    setLoading(true)

    axiosInstance.post(url,data||governmentJobInfo)
    .then ((response) => {
      setSuccessMessage(response.data.message)
      notifySuccess(response.data.message)
      getClose();
      refreshFunction && refreshFunction()
    })
    .catch ((error) => {
        //console.log(error)
    //   setErrorMessage(error.response.data.message)
      notifyError(error.response?.data?.message)
    })
    .finally (()=>{
      setLoading(false)
    })

  }
  

  const patchGovernmentJobInfo = (url,data,getClose,refreshFunction) => {

    setLoading(true)

    axiosInstance.patch(url,data)
    .then ((response) => {
      setSuccessMessage(response.data.message)
      notifySuccess(response.data.message)
      getClose();
      refreshFunction && refreshFunction()
    })
    .catch ((error) => {
        //console.log(error)
    //   setErrorMessage(error.response.data.message)
      notifyError(error.response?.data?.message)
    })
    .finally (()=>{
      setLoading(false)
    })

  }



  const deleteGovernmentJobInfo = (url,getClose,refreshFunction) => {

    setLoading(true)

    axiosInstance.delete(url)
    .then ((response) => {
      setSuccessMessage(response.data.message)
      notifySuccess(response.data.message)
      getClose();
      refreshFunction && refreshFunction()
    })
    .catch ((error) => {
        //console.log(error)
    //   setErrorMessage(error.response.data.message)
      notifyError(error.response?.data?.message)
    })
    .finally (()=>{
      setLoading(false)
    })

  }




    return <GovernmentJobContext.Provider
    value={{
      successMessage,
      errorMessage,
      loading,
      getGovernmentJobInfo,
      governmentJobInfo,
      setGovernmentJobInfo,
      postPatchGovernmentJobInfo,
      getGovernmentJobList,
      governmentJobList,
      setGovernmentJobList,
      deleteGovernmentJobInfo,
      patchGovernmentJobInfo

    }}>
        {children}
    </GovernmentJobContext.Provider>
}